import React, { useState } from "react";

import { createContext, useContext } from "react";
const userContext = createContext();
const userData = {
  id: "122vrg12344",
  image: "https://via.placeholder.com/100",
  name: "John Doe",
  email: "johndoe@example.com",
  role: "Admin",
  phone:"9648316865",
  pharmacy:" Delhi medicical Store Inc",
  prescriptionId:"123"
};
export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(userData);
  return (
    <userContext.Provider value={{ user, setUser }}>
      {children}
    </userContext.Provider>
  );
};

export const UseUser = () => useContext(userContext);
