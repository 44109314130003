import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Breadcrumb.css';  // Optional CSS for styling

function Breadcrumb() {
  const location = useLocation();
  
  // Split the location path to create breadcrumb segments
  const pathnames = location.pathname.split('/').filter(x => x);

  return (
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb" style={{marginBottom:"7px"}}>
        <li className="breadcrumb-item">
          <Link to="/dash2">Dashboard</Link>
        </li>
        {pathnames.map((value, index) => {
            let customValue = '';
            if(value == 'bookbyclinic'){
                customValue = 'Book Appointments';
            }else if(value == 'addnewpatientpoly'){
                customValue = 'Patient Registration';
            }else if(value == 'doctormanagement'){
                customValue = "Doctor's List";
          }else if(value == 'docregister'){
            customValue = "Doctor Registration Form";
      }
          const to = `/${pathnames.slice(0, index + 1).join('/')}`;
          const isLast = index === pathnames.length - 1;

          return isLast ? (
            <li className="breadcrumb-item active" aria-current="page" key={to}>
              {customValue}
            </li>
          ) : (
            <li className="breadcrumb-item" key={to}>
              <Link to={to}>
                {customValue}
              </Link>
            </li>
          );
        })}
      </ol>
    </nav>
  );
}

export default Breadcrumb;
