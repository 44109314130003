import { useContext, createContext, useState } from "react";
const AppointmentDoctor = createContext();

export const AppointmentDoctorProviderPoly = ({ children }) => {
  const [appointDoctor, setAppointDoctor] = useState({
    doctorId: "123",
    doctorName: " Dr. John Smith",
    doctorProfile: "https://via.placeholder.com/100",
    speciality: " Cardio ",
    appointments: ["10:00 AM", "11:00 AM ", "12:00 PM", "13:00 PM"],
  });

  return (
    <AppointmentDoctor.Provider value={{ appointDoctor, setAppointDoctor }}>
      {children}
    </AppointmentDoctor.Provider>
  );
};

export const useAppointmentDoctor = () => useContext(AppointmentDoctor);
