import React, { createContext, useContext, useState, useEffect } from "react";
// Create Doctor Context
const DoctorContext = createContext();

export const ClinicStaffUserProvider = ({ children }) => {
  // Initialize state from localStorage or with default values
  const [staff, setStaff] = useState(() => {
    const savedDoctor = localStorage.getItem("staff");
    return savedDoctor
      ? JSON.parse(savedDoctor)
      : {
          id: "13",
          loginid: "Ram123",
          clinicid: "4",
          password: "1234",
          licenseid: "12244RAMA",
          type: "NURSE",
          profileimg: "",
          name: "Madan Sharma ",
          staffid: "12365",
          mobilenumber: "9645123654",
          email: "madansharma@gmail.com",
        };
  });

  // Save doctor state to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem("staff", JSON.stringify(staff));
  }, [staff]);

  return (
    <DoctorContext.Provider value={{ staff, setStaff }}>
      {children}
    </DoctorContext.Provider>
  );
};

export const useClinicStaff = () => useContext(DoctorContext);
