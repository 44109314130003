import React, { createContext, useContext, useEffect, useState } from "react";

// Create Patient Context
const PatientContext = createContext();


export const PatientProviderPharma = ({ children }) => {
  
  const [patient, setPatient] = useState(() => {
    const savedPatient = localStorage.getItem('patient');
    return savedPatient
    ? JSON.parse(savedPatient)
     : {
      patientName: "",
      profile:"https://via.placeholder.com/100",
      age: "",
      id:"",
      sex: "",
      patientId: "",
      mobile: "",
      email: "",
      address:""
     };
  });

  useEffect(() => {
    localStorage.setItem('patient', JSON.stringify(patient));
  }, [patient]);

  return (
    <PatientContext.Provider value={{ patient, setPatient }}>
      {children}
    </PatientContext.Provider>
  );
};

export const usePatient = () => useContext(PatientContext);
