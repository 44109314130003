import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import AOS from "aos"; // Import AOS JS
import "aos/dist/aos.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/dist/jquery.min.js";
import "bootstrap/dist/js/bootstrap.bundle.js";
import "bootstrap-icons/font/bootstrap-icons.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Chart from "chart.js/auto"; // don't remove thiss line it is for graph of vitals chart

// import 'popper.js';
// import 'boxicons';

import { PatientProvider } from "./patientsection/contex/PatientContex";

import { PatientProviderEprscription } from "./Epresscription/context/PatientContext";
import { DoctorProvider } from "./Epresscription/context/DoctorContext";
import { WritePrescriptionProvider } from "./Epresscription/context/PrescriptionContext";
import { ClinicProviderEprescription } from "./Epresscription/context/ClinicContext";

import { PatientProviderLab } from "./Lab/context/PatientContext";
import { UserProvider } from "./Lab/context/UserContext";

import { PatientProviderPoly } from "./Pollyclinic/context/PatientContext";
// import { DoctorProviderPoly } from "./Pollyclinic/context/DoctorContext";
import { ClinicStaffUserProvider } from "./Pollyclinic/context/DoctorContext";

import { AppointmentDoctorProviderPoly } from "./Pollyclinic/context/AppointmentDoctor";


import { PatientProviderPharma } from "./Pharmacy/context/PatientContext";
import { UserProviderPharma } from "./Pharmacy/context/UserContext";


AOS.init();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>

    {/* Patient Provider  */}
    <PatientProvider>
      {/* LAB PROVIDER  */}
      <PatientProviderLab>
        <UserProvider>
          {/* DOCTOR PROVIDER  */}
          <PatientProviderEprscription>
            <ClinicProviderEprescription>
              <DoctorProvider>
                <WritePrescriptionProvider>
                  <PatientProviderPoly>
                    {/*  CLINCI STAFF PROVIDER  */}
                    <ClinicStaffUserProvider>
                
                        {/* <AppointmentDoctorProviderPoly> */}

                        {/* PHARMACY PROVIDER */}
                        <UserProviderPharma>
                          <PatientProviderPharma>

                            <App />
                            
                          </PatientProviderPharma>
                        </UserProviderPharma>
                
                      {/* </AppointmentDoctorProviderPoly> */}
                    </ClinicStaffUserProvider>
                  </PatientProviderPoly>
                </WritePrescriptionProvider>
              </DoctorProvider>
            </ClinicProviderEprescription>
          </PatientProviderEprscription>
        </UserProvider>
      </PatientProviderLab>
    </PatientProvider>
    <ToastContainer />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
