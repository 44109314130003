import React, { createContext, useContext, useState, useEffect } from "react";

// Create context
const ClinicContext = createContext();

// Create the ClinicProvider component
export const ClinicProviderEprescription = ({ children }) => {
  // Initialize state from sessionStorage or with default values
  const [clinic, setClinic] = useState(() => {
    const savedClinic = sessionStorage.getItem("clinic");
    return savedClinic
      ? JSON.parse(savedClinic)
      : {
          id: "",
          name: "",
          logo: "",
          phone: "",
          email: "",
          address: "",
          doctor:[],
          district: "",
          pincode: "",
          specialization: [],
          services: [],
          openingHours: [],
          rating: "",
        };
  });

  // Save clinic state to sessionStorage whenever it changes
  useEffect(() => {
    sessionStorage.setItem("clinic", JSON.stringify(clinic));
  }, [clinic]);

  return (
    // Use ClinicContext.Provider to pass down the clinic state and setter function
    <ClinicContext.Provider value={{ clinic, setClinic }}>
      {children}
    </ClinicContext.Provider>
  );
};

// Custom hook to use the ClinicContext
export const useClinicEprescription = () => useContext(ClinicContext);
