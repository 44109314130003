import React, { createContext, useContext, useEffect, useState } from "react";

// Create Patient Context
const PatientContext = createContext();



export const PatientProviderEprscription = ({ children }) => {

  // const [patient, setPatient] = useState(PatientData);

  // Initialize state from localStorage or with default values
  const [patient, setPatient] = useState(() => {
    const savedPatient = sessionStorage.getItem("patient");
    return savedPatient
      ? JSON.parse(savedPatient)
      : {
          patientName: "",
          profile: "https://via.placeholder.com/100",
          age: "",
          id: "",
          sex: "",
          patientId: "",
          mobile: "",
          email: "",
          address: "",
        };
  });

  // Save doctor state to localStorage whenever it changes
  useEffect(() => {
    sessionStorage.setItem("patient", JSON.stringify(patient));
  }, [patient]);

  return (
    <PatientContext.Provider value={{ patient, setPatient }}>
      {children}
    </PatientContext.Provider>
  );
};

export const usePatient = () => useContext(PatientContext);
