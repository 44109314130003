import React, { useState } from "react";

import { createContext, useContext } from "react";
const userContext = createContext();
const userData = {

  id: "",
  loginid: null,
  name: "",
  role: null,
  profileimg: null,
  licenseid: null,
  type: "",
  mobilenumber: "",
  email: "",
  fclty_id: "",
};
export const UserProviderPharma = ({ children }) => {
  const [PharmaUser, setPharmaUser] = useState(userData);
  return (
    <userContext.Provider value={{ PharmaUser, setPharmaUser }}>

      {children}
    </userContext.Provider>
  );
};

export const UseUser = () => useContext(userContext);
